import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { IAssignment } from "@ramudden/data-access/models/assignment";
import { BackendRights } from "@ramudden/data-access/models/backend-rights";
import { IDevice, IDeviceNavigator, IDeviceSummary } from "@ramudden/data-access/models/device";
import { IGroup } from "@ramudden/data-access/models/group";
import { ILocation } from "@ramudden/data-access/models/location";
import { IMeasuringPoint, IMeasuringPointNavigator } from "@ramudden/data-access/models/measuring-point";
import { IProject, IProjectNavigator } from "@ramudden/data-access/models/project";
import { Rights } from "@ramudden/data-access/models/rights";
import { ITask } from "@ramudden/data-access/models/task";
import { IUser, IUserSummary } from "@ramudden/data-access/models/user";
import { ILocationSummary } from "@ramudden/data-access/models/web";
import { MapDataService, UsersService } from "@ramudden/services";
import { GlobalEventsService } from "../../../services/global-events.service";

@Component({
    selector: "app-navigator",
    templateUrl: "./navigator.component.html",
})
export class NavigatorComponent implements OnChanges, OnDestroy {
    @Input() assignment: IAssignment;
    @Input() assignmentId?: number;
    @Input() assignmentName: string;
    @Input() device?: IDevice | IDeviceSummary | IDeviceNavigator;
    @Input() enabled = true;
    @Input() group?: IGroup;
    @Input() location?: ILocation | ILocationSummary;
    @Input() measuringPoint?: IMeasuringPoint | IMeasuringPointNavigator;
    @Input() newTab = false;
    @Input() ngStyle: any;
    @Input() organizationId?: number;
    @Input() project: IProject | IProjectNavigator;
    @Input() showIcon = false;
    @Input() task: ITask;
    @Input() user: IUser | IUserSummary;
    @Input() userId: number;

    protected text: string;
    protected navigationUrl: string;
    protected icon: string;

    // the right of navigation
    protected canNavigate = false;
    protected isInactive = false;

    // if target is reachable
    // imagine a DeviceLink on MP with owner company X, but linked to a device with owner Signco
    // we will allow the user to click on the link (canNavigate),
    // but show a popup informing them the navigation cannot continue
    // Currently we only have situations pertaining to devices / measuring points
    targetNotReachable = false;

    queryParams: object;
    private subscriptionManager = new SubscriptionManager();
    private rights: Rights;

    constructor(
        private readonly mapDataService: MapDataService,
        private readonly usersService: UsersService,
        private readonly globalEventsService: GlobalEventsService,
    ) {
        const rightsSubscription = this.globalEventsService.currentRights$.subscribe(
            (rights) => (this.rights = rights),
        );
        this.subscriptionManager.add("rightsSubscription", rightsSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    ngOnChanges(changes: SimpleChanges) {
        const contextChange =
            changes["measuringPoint"] ||
            changes["location"] ||
            changes["device"] ||
            changes["group"] ||
            changes["organizationId"] ||
            changes["user"] ||
            changes["userId"] ||
            changes["assignment"] ||
            changes["assignmentId"] ||
            changes["assignmentName"] ||
            changes["project"];

        const enabledChange = changes["enabled"];

        if (contextChange || enabledChange) {
            this.update();
        }
    }

    private async update() {
        this.queryParams = null;
        this.isInactive = false;
        this.icon = null;

        if (this.measuringPoint) {
            this.text = this.measuringPoint.code;

            const navigator = this.measuringPoint as IMeasuringPointNavigator;
            if (navigator.locationId) {
                this.navigationUrl = `/locations/${navigator.locationId}/${navigator.id}`;
            } else {
                const measuringPoint = this.measuringPoint as IMeasuringPoint;

                if (!measuringPoint.location) {
                    console.warn("MeasuringPoint did not have a location, cannot navigate");
                } else {
                    this.navigationUrl = `/locations/${measuringPoint.location.id}/${this.measuringPoint.id}`;
                }
            }

            this.queryParams = null;
            this.canNavigate =
                !this.measuringPoint.isObsolete && this.rights?.hasBackendRight(BackendRights.ViewMeasuringPoint);
            this.targetNotReachable = false;
            this.icon = "measuring-points";
        }

        if (this.location) {
            this.text = this.location.code;
            this.navigationUrl = "/locations";
            this.queryParams = { locationId: this.location.id };
            this.canNavigate = this.rights?.hasBackendRight(BackendRights.ViewMeasuringPoint);
            this.targetNotReachable = !this.globalEventsService.assertAccess(this.location.ownerId);
            this.icon = "locations";
        }

        if (this.device) {
            this.text = this.device.code;
            this.navigationUrl = `/devices/${this.device.id}`;
            this.queryParams = null;
            this.canNavigate = !this.device.isObsolete && this.rights?.hasBackendRight(BackendRights.ViewDevice);
            this.targetNotReachable = !this.globalEventsService.assertAccess(this.device.ownerId);
            this.icon = "hardware";
        }

        if (this.group) {
            this.text = this.group.code;
            this.navigationUrl = "/groups";
            this.queryParams = { id: this.group.id };
            this.canNavigate = !this.group.isObsolete && this.rights?.hasBackendRight(BackendRights.ViewGroup);
            this.targetNotReachable = !this.globalEventsService.assertAccess(this.group.ownerId);
            this.icon = "groups";
        }

        if (this.organizationId) {
            const organization = await this.mapDataService.getOrganization$((x) => x.id === this.organizationId);

            if (!organization) {
                this.targetNotReachable = true;
                console.error("Couldn't fetch organization", this.organizationId);
            } else {
                this.text = organization.name;
                this.navigationUrl = `/administration/organization/${this.organizationId}/details`;
                this.queryParams = null;
                this.canNavigate =
                    !organization.isObsolete && this.rights?.hasBackendRight(BackendRights.ViewOrganization);
            }

            this.icon = "organizations";
        }

        if (this.assignmentId) {
            this.text = this.assignmentName;
            this.navigationUrl = `/assignments/${this.assignmentId}/details`;
            this.queryParams = null;
            this.canNavigate = this.rights?.hasBackendRight(BackendRights.ViewAssignment);
            this.icon = "assignments";
        }

        if (this.project) {
            this.text = this.project.name;
            this.navigationUrl = `/administration/project/${this.project.id}/details`;
            this.queryParams = null;
            this.canNavigate =
                !this.project.isObsolete &&
                this.rights?.hasBackendRight(BackendRights.ViewProject) &&
                this.globalEventsService.assertAccess(this.project.organizationId);
            this.isInactive = !this.project.isActive;
            this.icon = "projects";
        }

        if (this.assignment) {
            this.text = this.assignment.name;
            this.navigationUrl = `/assignments/${this.assignment.id}/details`;
            this.queryParams = null;
            this.canNavigate =
                !this.assignment.isObsolete && this.rights?.hasBackendRight(BackendRights.ViewAssignment);
            this.icon = "assignments";
        }

        if (this.task && this.task.assignment) {
            this.text = this.task.name;
            this.navigationUrl = `/task/${this.task.id}/details`;
            this.queryParams = null;
            this.canNavigate = !this.task.isObsolete && this.rights?.hasBackendRight(BackendRights.ViewTask);
            this.icon = "assignments";
        }

        if (this.userId) {
            const user = await this.usersService.getUser$((x) => x.id === this.userId);
            if (user) {
                this.user = user;
            }
        }

        if (this.user) {
            this.text = `${this.user.firstName || ""} ${this.user.lastName || ""}`;

            let organizationId: number = null;
            const user = this.user as IUser;
            if (user && user.userOrganizations && user.userOrganizations.length > 0) {
                organizationId = user.userOrganizations.map((x) => x.organization).takeFirstOrDefault().id;
            } else {
                const userSummary = this.user as IUserSummary;
                if (userSummary && userSummary.organizationIds && userSummary.organizationIds.length > 0) {
                    organizationId = userSummary.organizationIds.takeFirstOrDefault();
                }
            }

            this.navigationUrl = `/administration/organization/${organizationId}/details`;
            this.canNavigate =
                !!organizationId && !this.user.isObsolete && this.globalEventsService.assertAccess(organizationId);

            this.queryParams = null;
            this.icon = "user";
        }

        if (!this.text) {
            this.clear();
        }

        this.canNavigate = this.canNavigate && this.enabled;
    }

    private clear() {
        this.text = "";
        this.navigationUrl = "";
        this.icon = null;
        this.canNavigate = false;
        this.queryParams = null;
    }
}
