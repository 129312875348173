import { AfterViewInit, Component, inject, NgZone, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Data, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { IEnvironment } from "@ramudden/data-access/models/environment";
import {
    ConfigurationService,
    LocalStorageService,
    PromptUpdateService,
    TitleService,
    WebsiteService,
} from "@ramudden/services";
import { ModalService } from "@ramudden/ui";
import { Angulartics2GoogleTagManager } from "angulartics2";
import Bowser from "bowser";
import moment from "moment";
import { filter, map, mergeMap } from "rxjs/operators";
import { environment } from "../environments/environment";
import { AuthenticationService } from "./services/authentication.service";
import { DomainDataService } from "./services/domain-data.service";
import { GalleriaService } from "./services/galleria.service";
import { GlobalEventsService } from "./services/global-events.service";
import { NavigationService } from "./services/navigation.service";
import { GalleriaComponent } from "./shared/components/galleria/galleria.component";
// declare let Zone: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild("modalContainer", { read: ViewContainerRef, static: true }) modalContainer!: ViewContainerRef;
    @ViewChild(GalleriaComponent, { static: true }) galleriaComponent: GalleriaComponent;

    environment: IEnvironment;
    isIframe: boolean;

    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly titleService = inject(TitleService);
    private readonly translate = inject(TranslateService);
    private readonly zone = inject(NgZone);
    private readonly galleriaService = inject(GalleriaService);
    private readonly configurationService = inject(ConfigurationService);
    angulartics2GoogleTagManager = inject(Angulartics2GoogleTagManager);
    private readonly authenticationService = inject(AuthenticationService);
    private readonly globalEventsService = inject(GlobalEventsService);
    private readonly title = inject(Title);
    private readonly websiteService = inject(WebsiteService);
    private readonly domainDataService = inject(DomainDataService);
    private readonly promptUpdateService = inject(PromptUpdateService);
    private readonly modalService = inject(ModalService);

    constructor() {
        this.promptUpdateService.subscribeToUpdates();

        // Fallback when app_initializer failed
        AppComponent.attemptStartup(this.configurationService, this.domainDataService);

        this.angulartics2GoogleTagManager.startTracking();
        this.authenticationService.initialize();

        this.environment = this.configurationService.configuration;

        this.loadGoogleTag();
        this.loadAdditionalStyleSheets();

        this.configureTranslations();
        this.overwriteEvents();

        this.title.setTitle(this.websiteService.getProductName());
    }

    static async attemptStartup(
        configService: ConfigurationService,
        domainDataService: DomainDataService,
    ): Promise<void> {
        try {
            if (!configService.configuration) {
                await configService.loadConfiguration(environment);
            }

            if (!domainDataService.domainData) {
                await domainDataService.load();
            }
        } catch (error) {
            if (!environment.production) {
                console.error(error);
            }

            // ignored, because if any of this errors, our app doesn't load
            // We, however, would very much like our app to load.
            // domainDataService will trigger ErrorService if anything is wrong
            // which in turn will send us to /status
            // which in turn will reload the app if the API is back to normal
        }
    }

    ngOnInit() {
        this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
        // Fill in Title according to route.data.name
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd))
            .subscribe((event: NavigationStart | NavigationEnd) => {
                NavigationService.isNavigating = event instanceof NavigationStart;
            });

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route: ActivatedRoute) => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter((route: ActivatedRoute) => route.outlet === "primary"),
                mergeMap((route: ActivatedRoute) => route.data),
            )
            .subscribe((data: Data) => {
                if (data.name) {
                    this.titleService.setTitle(data.name);
                }
            });

        this.domainDataService.updateTranslations(); // SCS-666 Make sure translations are loaded properly, sanity check
    }

    ngAfterViewInit() {
        this.modalService.setRootViewContainerRef(this.modalContainer);
        this.galleriaService.register(this.galleriaComponent);

        this.checkForValidBrowser();
    }

    // private checkedCounter: number = 0;
    // ngDoCheck() {
    //     console.log("Checked", this.checkedCounter++);
    // }

    private configureTranslations() {
        // this language will be used as a fallback when a translation isn't found in the current language
        const languages = ["nl", "en", "de", "fr", "sv", "no"];
        const defaultLang = "en";
        this.translate.setDefaultLang(defaultLang);

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        let initialLanguage = this.localStorageService.getItem("language") || defaultLang;

        if (!languages.contains(initialLanguage)) {
            initialLanguage = defaultLang;
        }

        this.translate.use(initialLanguage);

        this.translate.onLangChange.subscribe(() => {
            this.localStorageService.setItem("language", this.translate.currentLang);

            let locale = "en-GB";
            if (this.translate.currentLang === "nl") {
                locale = "nl-BE";
            } else if (this.translate.currentLang === "fr") {
                locale = "fr";
            } else if (this.translate.currentLang === "de") {
                locale = "de";
            } else if (this.translate.currentLang === "sv") {
                locale = "sv";
            } else if (this.translate.currentLang === "no") {
                locale = "nb";
            }
            moment.locale(locale);
        });

        this.globalEventsService.authorizationInfo$.subscribe((authorizationInfo) => {
            if (authorizationInfo) {
                const language = authorizationInfo.user.languageId;
                if (language) {
                    this.translate.use(language);
                }
            }
        });
    }

    private overwriteEvents() {
        // platform-browser adds a `mousemove` event
        // this triggers change-detection ALL THE TIME
        // run it outside of Angular
        // Angular by itself attaches a `click` event (`wrapFn`)
        const originalDocumentListener = document ? document.addEventListener : null;
        if (originalDocumentListener) {
            document.addEventListener = (type: string, listener: any, options: any) => {
                if (type === "mousemove") {
                    this.zone.runOutsideAngular(() => {
                        originalDocumentListener.call(document, type, listener, options);
                    });
                    return;
                }

                originalDocumentListener.call(document, type, listener, options);
            };
        }
    }

    private loadGoogleTag() {
        if (!this.environment.googleTagKey) return;

        window["dataLayer"] = window["dataLayer"] || [];
        window["dataLayer"].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
        });

        const scriptNode = document.createElement("script");

        scriptNode.async = true;
        scriptNode.src = "https://www.googletagmanager.com/gtm.js?id=" + this.environment.googleTagKey;
        scriptNode.nonce = "tagmanager";
        document.head.appendChild(scriptNode);
    }

    private loadAdditionalStyleSheets() {
        if (!window) return;

        // const browser = Bowser.getParser(window.navigator.userAgent);
        // if (browser.getBrowserName() === "Chrome" && !this.resizeService.isMobile) {
        //     const ua = browser.getBrowserVersion();
        //     const versionString = browser.getBrowserVersion();
        //     const version = Number.parseInt(versionString.substr(0, versionString.indexOf(".")), 10);
        //     if (version >= 83) {
        //         const chromeStyle = document.createElement("style");
        //         // chromeStyle.innerText = "input[type=\"checkbox\"] {-webkit-filter: brightness(0.9);} input[type=\"checkbox\"]:checked {-webkit-filter: grayscale(100%) invert(100%) brightness(1.3);}";
        //         document.head.appendChild(chromeStyle);
        //     }
        // }
    }

    private checkForValidBrowser() {
        const didGetNotificationStorageKey = "incompatibleBrowserNotification";
        const didGetNotificationAlready = !!this.localStorageService.getItem(didGetNotificationStorageKey);
        if (didGetNotificationAlready) return;

        // Check for valid browsers, if not, show a notification
        const bowser = Bowser.getParser(window.navigator.userAgent);
        bowser.getPlatformType();

        const microsoftEdge = "Microsoft Edge";
        const compatibleBrowsers = ["Chrome", "Firefox", "Opera", microsoftEdge];

        const showError = () => {
            this.localStorageService.setItem(didGetNotificationStorageKey, "true");

            setTimeout(() => {
                this.modalService.error("errors.incompatibleBrowser");
            });
        };

        const browserName = bowser.getBrowserName();
        if (!compatibleBrowsers.contains(browserName)) {
            showError();
        } else {
            if (browserName === microsoftEdge && bowser.getEngineName() !== "Blink") {
                showError();
            }
        }
    }
}
