import { inject, Injectable } from "@angular/core";
import { ICameraMetaInfo, ICameraMoveOptions } from "@ramudden/data-access/models/camera";
import { ApiServiceBase } from "@ramudden/data-access/resource/api";
import { DownloadedFile, DownloadFileService } from "@ramudden/services";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CameraApi extends ApiServiceBase {
    private readonly downloadFileService = inject(DownloadFileService);

    override getRoute(): string {
        return "Cameras";
    }

    getImageForDevice(id: number): Promise<DownloadedFile> {
        const url = `${super.getUrl()}/devices/${id}`;
        return this.downloadFileService.downloadBlob(url);
    }

    getImageForMeasuringPoint(id: number): Promise<DownloadedFile> {
        const url = `${super.getUrl()}/measuringpoint/${id}`;
        return this.downloadFileService.downloadBlob(url);
    }

    moveDevice$(id: number, options: ICameraMoveOptions): Observable<void> {
        const url = `${super.getUrl()}/devices/${id}/move`;
        return this.http.post<void>(url, options);
    }

    moveMeasuringPoint$(id: number, options: ICameraMoveOptions): Observable<void> {
        const url = `${super.getUrl()}/measuringpoint/${id}/move`;
        return this.http.post<void>(url, options);
    }

    getInfoForDevice$(id: number): Observable<ICameraMetaInfo> {
        const url = `${super.getUrl()}/devices/${id}/info`;
        return this.http.get<ICameraMetaInfo>(url);
    }

    getInfoForMeasuringPoint$(id: number): Observable<ICameraMetaInfo> {
        const url = `${super.getUrl()}/measuringpoint/${id}/info`;
        return this.http.get<ICameraMetaInfo>(url);
    }

    getLivestreamRouteForMeasuringPoint(id: number, token: string) {
        const url = `${super.getUrl()}/measuringpoint/${id}/streamingvideo?access_token=${token}`;
        return url;
    }

    getLivestreamRouteForDevice(id: number, token: string) {
        const url = `${super.getUrl()}/devices/${id}/streamingvideo?access_token=${token}`;
        return url;
    }
}
