import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
    ApplicationCreator,
    ApplicationUpdater,
    IApplication,
    IClientCreatedResult,
} from "@ramudden/data-access/models/application";
import { ViewModelEnum } from "@ramudden/data-access/models/domain-data";
import { SigncoFormControl, SigncoFormGroup } from "@ramudden/data-access/models/form";
import { AuthorizationInfo } from "@ramudden/data-access/models/user";
import { ApplicationApi } from "@ramudden/data-access/resource/application.api";
import { FormValidationService } from "@ramudden/services";
import { SelectItem } from "primeng/api";
import { DomainData, DomainDataService } from "../../../services/domain-data.service";
import { DomainModelFilterService } from "../../../services/domain-model-filter.service";
import { GlobalEventsService } from "../../../services/global-events.service";
import { DialogComponentBase } from "../dialog/dialog.component";

class GroupedRight {
    key: string;
    label: string;
    rights: ViewModelEnum[] = [];
}

@Component({
    selector: "app-manage-application-dialog",
    templateUrl: "./manage-application.dialog.html",
})
export class ManageApplicationDialogComponent extends DialogComponentBase implements OnInit {
    submitting: boolean;
    callback: (res: IApplication | IClientCreatedResult) => void;
    dialogForm: SigncoFormGroup;
    rightsForm: SigncoFormGroup;
    users: SelectItem[];

    existingApplication: IApplication;

    rightViewModels: ViewModelEnum[];
    groupedRights = new Array<GroupedRight>();
    authorizationInfo: AuthorizationInfo;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly domainModelFilterService: DomainModelFilterService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly applicationApi: ApplicationApi,
        private readonly domainDataService: DomainDataService,
        private readonly translate: TranslateService,
    ) {
        super();

        this.domainDataService.get(DomainData.Rights).then((rightViewModels) => {
            this.rightViewModels = rightViewModels;
            this.groupRights();
        });
    }

    async ngOnInit(): Promise<void> {
        this.authorizationInfo = this.globalEventsService.getAuthorizationInfo();
        if (this.authorizationInfo && this.authorizationInfo.isActualDomainAdministrator) {
            this.users = await this.domainModelFilterService.getUsers$();
        }
    }

    create(callback?: (res: IClientCreatedResult) => void) {
        this.callback = callback;
        this.existingApplication = null;

        this.openDialog();
    }

    edit(existingApplication: IApplication, callback?: (res: IApplication) => void) {
        this.callback = callback;
        this.existingApplication = existingApplication;

        this.openDialog();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.dialogForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (updatedApplication: IApplication | IClientCreatedResult) => {
            if (this.existingApplication) {
                Object.assign(this.existingApplication, updatedApplication);
            }

            if (this.callback) {
                this.callback(updatedApplication);
            }

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        const updateRights = (creator: ApplicationCreator) => {
            creator.rights = [];

            for (const rightViewModel of this.rightViewModels) {
                const rightControl = this.rightsForm.get(rightViewModel.value);
                if (rightControl.value === true) {
                    creator.rights.push(rightViewModel.value);
                }
            }
        };

        if (!this.existingApplication) {
            const creator = Object.assign(new ApplicationCreator(), this.dialogForm.value) as ApplicationCreator;
            updateRights(creator);
            this.applicationApi.create$(creator).subscribe(onSuccess, onError);
        } else {
            const updater = Object.assign(
                new ApplicationUpdater(this.existingApplication),
                this.dialogForm.value,
            ) as ApplicationUpdater;
            updateRights(updater);
            this.applicationApi.update$(updater).subscribe(onSuccess, onError);
        }
    }

    selectGroup(groupedRight: GroupedRight) {
        const controls = new Array<SigncoFormControl>();
        for (const right of groupedRight.rights) {
            controls.push(this.rightsForm.get(right.value));
        }

        const hasUnselected = controls.find((x) => !x.value);

        controls.forEach((x) => x.setValue(hasUnselected));
    }

    groupedRightTrackByFn(index: number, item: GroupedRight) {
        return item.key;
    }

    rightTrackByFn(index: number, item: ViewModelEnum) {
        return item.value;
    }

    protected onOpen() {
        this.rightsForm = this.formBuilder.group({}) as SigncoFormGroup;
        for (const rightViewModel of this.rightViewModels) {
            this.rightsForm.addControl(rightViewModel.value, new UntypedFormControl(false));
        }

        const clientIdControl = this.formBuilder.control(null);
        clientIdControl.disable();

        this.dialogForm = this.formBuilder.group({
            clientId: clientIdControl,
            description: ["", Validators.required],
            rights: this.rightsForm,
        }) as SigncoFormGroup;

        if (this.existingApplication) {
            this.dialogForm.patchValue(this.existingApplication);

            for (const right of this.existingApplication.rights) {
                const control = this.rightsForm.get(right);

                // Rights can be deleted or obsolete, having them not show up in UI but still attached to API
                if (control) {
                    control.setValue(true);
                }
            }
        }

        if (this.authorizationInfo && this.authorizationInfo.isDomainAdministrator) {
            const userId = this.existingApplication ? this.existingApplication.user.id : this.authorizationInfo.user.id;
            const userIdControl = this.formBuilder.control(userId);
            this.dialogForm.addControl("userId", userIdControl);
        }

        this.submitting = false;
    }

    protected onClose() {
        this.dialogForm = null;
        this.rightsForm = null;
    }

    private groupRights() {
        const groupedRights = new Array<GroupedRight>();

        const getRightKey = (right: string): string => {
            const filteredWords = [
                "view",
                "create",
                "edit",
                "delete",
                "uploadPulsesIn",
                "upload",
                "render",
                "download",
            ];
            let rightKey = right;
            for (const filteredWord of filteredWords) {
                rightKey = rightKey.replace(filteredWord, "");
            }

            return rightKey.toCamelCase();
        };

        for (const right of this.rightViewModels) {
            const key = getRightKey(right.value);

            let groupedRight = groupedRights.find((x) => x.key === key);

            if (!groupedRight) {
                groupedRight = {
                    key: key,
                    label: this.translate.instant(`applications.rights.${key}`),
                    rights: [],
                };

                groupedRights.push(groupedRight);
            }

            groupedRight.rights.push(right);
        }

        const orderRight = (groupedRight: GroupedRight, startsWith: string, index: number) => {
            const right = groupedRight.rights.find((x) => x.value.startsWith(startsWith));
            if (right) {
                groupedRight.rights = groupedRight.rights.remove(right);
                groupedRight.rights.insert(right, index);
            }
        };

        for (const groupedRight of groupedRights) {
            orderRight(groupedRight, "view", 0);
            orderRight(groupedRight, "create", 1);
            orderRight(groupedRight, "edit", 2);
            orderRight(groupedRight, "delete", 3);
        }

        this.groupedRights = groupedRights.orderBy((x) => x.label);
    }
}
