import { Injectable } from "@angular/core";
import { IPlannedEvent, PlannedEventCreator, PlannedEventUpdater } from "@ramudden/data-access/models/planned-event";
import { IWorker } from "@ramudden/data-access/models/worker";
import moment from "moment";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class PlannedEventApi extends ApiService<IPlannedEvent, PlannedEventCreator, PlannedEventUpdater> {
    override getRoute(): string {
        return "PlannedEvents";
    }

    copyWeek$(startingDateOfWeekWhichNeedsToBeCopied: Date): Observable<void> {
        return this.http.post<void>(
            `${this.getUrl()}/CopyWeek?startOfTheWeekWhichNeedsToBeCopied=${startingDateOfWeekWhichNeedsToBeCopied.toISOString()}`,
            null,
        );
    }

    endWorkdayFromWebsite$(plannedEventId: number): Observable<void> {
        return this.http.post<void>(`${this.getUrl()}/EndWorkdayFromWebsite/${plannedEventId}`, null);
    }

    endWorkday(workerId: number) {
        return this.http.post<void>(`${this.getUrl()}/EndWorkday/${workerId}`, null);
    }

    availableWorkersForDate$(date: Date): Observable<IWorker[]> {
        return this.http.get<IWorker[]>(
            `${this.getUrl()}/AvailableWorkersForDate?forDate=${moment(date).format("YYYY/MM/DD")}`,
        );
    }
}
