/// <reference types="google.maps" />
import { ICoordinate } from "@ramudden/data-access/models/location";
import { ILocationSummary } from "@ramudden/data-access/models/web";

export class MapUtils {
    // https://github.com/indy256/convexhull-js/blob/master/convexhull.js
    public static convexHull(points: google.maps.LatLng[]): google.maps.MVCArray<google.maps.LatLng> {
        const hull = new google.maps.MVCArray<google.maps.LatLng>();
        if (!points || !points.length) return hull;

        points.sort((a, b) => (a.lat() !== b.lat() ? a.lat() - b.lat() : a.lng() - b.lng()));

        const n = points.length;

        const removeMiddle = (a: google.maps.LatLng, b: google.maps.LatLng, c: google.maps.LatLng) => {
            const cross = (a.lat() - b.lat()) * (c.lng() - b.lng()) - (a.lng() - b.lng()) * (c.lat() - b.lat());
            const dot = (a.lat() - b.lat()) * (c.lat() - b.lat()) + (a.lng() - b.lng()) * (c.lng() - b.lng());
            return cross < 0 || (cross === 0 && dot <= 0);
        };

        for (let i = 0; i < 2 * n; i++) {
            const j = i < n ? i : 2 * n - 1 - i;

            while (
                hull.getLength() >= 2 &&
                removeMiddle(hull.getAt(hull.getLength() - 2), hull.getAt(hull.getLength() - 1), points[j])
            ) {
                hull.pop();
            }

            hull.push(points[j]);
        }

        hull.pop();

        return hull;
    }

    public static toLatLng(input: ICoordinate | ILocationSummary): google.maps.LatLng {
        // lat or lng could be 0 (falsy), this implementation takes that into account

        if ("latitude" in input && "longitude" in input) {
            return new google.maps.LatLng(input.latitude, input.longitude);
        }

        if ("lat" in input && "lng" in input) {
            return new google.maps.LatLng(input.lat, input.lng);
        }

        console.error("The input cannot be converted to a LatLng object", input);
        throw new Error("Invalid coordinate");
    }

    /**
     * Take a map location and offset it by a certain amount of screen pixels.
     */
    public static offset(
        map: google.maps.Map,
        latlng: google.maps.LatLng,
        offsetX: number,
        offsetY: number,
    ): google.maps.LatLng {
        // Implementation based on ChatGPT

        // Convert the target lat/lng to world coordinates
        const projection = map.getProjection();
        const worldCoordinateTarget = projection.fromLatLngToPoint(latlng);

        // Calculate pixel offset based on the desired percentage
        const scale = Math.pow(2, map.getZoom());
        const pixelOffset = new google.maps.Point(offsetX / scale || 0, offsetY / scale || 0);

        // Calculate new world coordinates by applying the offset
        const worldCoordinateNewCenter = new google.maps.Point(
            worldCoordinateTarget.x - pixelOffset.x,
            worldCoordinateTarget.y - pixelOffset.y,
        );

        // Convert the new world coordinates back to lat/lng and pan the map to it
        const newCenter = projection.fromPointToLatLng(worldCoordinateNewCenter);
        return newCenter;
    }

    public static mouseEventToCoordinate(mouseEvent: google.maps.MapMouseEvent): ICoordinate {
        return {
            latitude: mouseEvent.latLng.lat(),
            longitude: mouseEvent.latLng.lng(),
        } as ICoordinate;
    }
}
