import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { ReportDirection } from "@ramudden/data-access/models/report-type";
import { MapDetail } from "../../../services/map-detail.service";
import { MapSelectionService } from "../../../services/map-selection.service";
import { SelectDevicesComponent } from "../select-devices/select-devices.component";
import { SelectGroupsComponent } from "../select-groups/select-groups.component";
import { SelectMeasuringPointsComponent } from "../select-measuring-points/select-measuring-points.component";
import { SelectOrganizationsComponent } from "../select-organizations/select-organizations.component";
import { SelectProjectsComponent } from "../select-projects/select-projects.component";
import { SelectScenariosComponent } from "../select-scenarios/select-scenarios.component";

export enum SelectionMode {
    MeasuringPoints = 0,
    Groups = 1,
    Projects = 2,
    Organizations = 3,
    Devices = 4,
    Scenarios = 5,
}

@Component({
    selector: "app-resource-selector",
    templateUrl: "./resource-selector.component.html",
    styleUrls: ["./resource-selector.component.scss"],
})
export class ResourceSelectorComponent implements OnDestroy, OnChanges {
    @ViewChild(SelectMeasuringPointsComponent, { static: true })
    measuringPointsComponent: SelectMeasuringPointsComponent;
    @ViewChild(SelectGroupsComponent, { static: true }) groupsComponent: SelectGroupsComponent;
    @ViewChild(SelectProjectsComponent, { static: true }) projectsComponent: SelectProjectsComponent;
    @ViewChild(SelectDevicesComponent, { static: true }) devicesComponent: SelectDevicesComponent;
    @ViewChild(SelectOrganizationsComponent, { static: true }) organizationsComponent: SelectOrganizationsComponent;
    @ViewChild(SelectScenariosComponent, { static: true }) scenariosComponent: SelectScenariosComponent;

    @Input() projectSelection = true;
    @Input() multipleProjectsSelection = true;
    @Input() requiredSelectedProject = false;

    @Input() measuringPointsSelection = true;
    @Input() measuringPointsWithDirection = false;
    @Input() multipleMeasuringPointsSelection = true;
    @Input() allowedDirections: ReportDirection[];
    @Input() requiredSelectedMeasuringPoint = false;

    @Input() groupsSelection = true;
    @Input() multipleGroupsSelection = true;
    @Input() requiredSelectedGroup = false;

    @Input() organizationsSelection = false;
    @Input() multipleOrganizationsSelection = true;
    @Input() requiredSelectedOrganization = false;

    @Input() devicesSelection = false;
    @Input() multipleDevicesSelection = true;
    @Input() requiredSelectedDevice = false;

    @Input() scenariosSelection = false;
    @Input() multipleScenariosSelection = true;
    @Input() requiredSelectedScenario = false;

    @Input() requireAtLeastOneResourceSelected = false;

    @Input() isMapOpen = false;
    @Input() validationEnabled = true;
    @Input() containingFormSubmitted = false;
    @Input() hidden = false;
    @Input() deleteCommand = true;

    @Output() toggleMap = new EventEmitter<MapDetail>();
    @Output() tabChange = new EventEmitter<SelectionMode>();

    selectionMode: SelectionMode = SelectionMode.MeasuringPoints;
    currentlySelectedMeasuringPoints = 0;
    currentlySelectedProjects = 0;
    currentlySelectedGroups = 0;
    currentlySelectedDevices = 0;
    currentlySelectedOrganizations = 0;
    currentlySelectedScenarios = 0;

    private subscriptionManager = new SubscriptionManager();

    constructor(
        private readonly selectionService: MapSelectionService,
        cdr: ChangeDetectorRef,
    ) {
        this.selectionService.subscribeToMeasuringPoints(
            this.subscriptionManager,
            () => {
                this.currentlySelectedMeasuringPoints = this.selectionService.getSelectedMeasuringPoints().length;
                cdr.detectChanges();
            },
            () => {
                this.currentlySelectedMeasuringPoints = this.selectionService.getSelectedMeasuringPoints().length;
                cdr.detectChanges();
            },
        );

        this.selectionService.subscribeToGroups(
            this.subscriptionManager,
            () => {
                this.currentlySelectedGroups = this.selectionService.getSelectedGroups().length;
                cdr.detectChanges();
            },
            () => {
                this.currentlySelectedGroups = this.selectionService.getSelectedGroups().length;
                cdr.detectChanges();
            },
        );

        this.selectionService.subscribeToProjects(
            this.subscriptionManager,
            () => {
                this.currentlySelectedProjects = this.selectionService.getSelectedProjects().length;
                cdr.detectChanges();
            },
            () => {
                this.currentlySelectedProjects = this.selectionService.getSelectedProjects().length;
                cdr.detectChanges();
            },
        );

        this.selectionService.subscribeToDevices(
            this.subscriptionManager,
            () => {
                this.currentlySelectedDevices = this.selectionService.getSelectedDevices().length;
                cdr.detectChanges();
            },
            () => {
                this.currentlySelectedDevices = this.selectionService.getSelectedDevices().length;
                cdr.detectChanges();
            },
        );

        this.selectionService.subscribeToOrganizations(
            this.subscriptionManager,
            () => {
                this.currentlySelectedOrganizations = this.selectionService.getSelectedOrganizations().length;
                cdr.detectChanges();
            },
            () => {
                this.currentlySelectedOrganizations = this.selectionService.getSelectedOrganizations().length;
                cdr.detectChanges();
            },
        );

        this.selectionService.subscribeToScenarios(
            this.subscriptionManager,
            () => {
                this.currentlySelectedScenarios = this.selectionService.getSelectedScenarios().length;
                cdr.detectChanges();
            },
            () => {
                this.currentlySelectedScenarios = this.selectionService.getSelectedScenarios().length;
                cdr.detectChanges();
            },
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        const modeChange =
            changes["projectSelection"] ||
            changes["measuringPointsSelection"] ||
            changes["groupsSelection"] ||
            changes["organizationsSelection"] ||
            changes["devicesSelection"] ||
            changes["scenariosSelection"];

        if (modeChange) this.selectDefaultIfCurrentlySelectedModeIsNotAvailable();
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    updateMapDetail() {
        if (this.selectionMode === SelectionMode.MeasuringPoints) this.toggleMap.emit(MapDetail.MeasuringPoints);
        else if (this.selectionMode === SelectionMode.Groups) this.toggleMap.emit(MapDetail.MeasuringPointGroups);
        else if (this.selectionMode === SelectionMode.Devices) this.toggleMap.emit(MapDetail.Devices);
        else if (this.selectionMode === SelectionMode.Organizations) this.toggleMap.emit(MapDetail.Organizations);
        else if (this.selectionMode === SelectionMode.Scenarios) this.toggleMap.emit(MapDetail.Scenarios);
        else this.toggleMap.emit(MapDetail.Projects);
    }

    setSelectionMode(selectionMode: SelectionMode, emitToggleMap = true) {
        if (this.selectionMode === selectionMode) return;

        this.selectionMode = selectionMode;
        if (this.isMapOpen && emitToggleMap) this.updateMapDetail();
        this.tabChange.emit(this.selectionMode);
    }

    selectDefaultIfCurrentlySelectedModeIsNotAvailable() {
        if (this.isCurrentlySelectedModeAvailable()) return;

        this.setFirstAvailableMode();
    }

    private isCurrentlySelectedModeAvailable(): boolean {
        return (
            (this.selectionMode === SelectionMode.MeasuringPoints && this.measuringPointsSelection) ||
            (this.selectionMode === SelectionMode.Devices && this.devicesSelection) ||
            (this.selectionMode === SelectionMode.Organizations && this.organizationsSelection) ||
            (this.selectionMode === SelectionMode.Groups && this.groupsSelection) ||
            (this.selectionMode === SelectionMode.Projects && this.projectSelection) ||
            (this.selectionMode === SelectionMode.Scenarios && this.scenariosSelection)
        );
    }

    private setFirstAvailableMode() {
        if (this.measuringPointsSelection) this.selectionMode = SelectionMode.MeasuringPoints;
        else if (this.groupsSelection) this.selectionMode = SelectionMode.Groups;
        else if (this.projectSelection) this.selectionMode = SelectionMode.Projects;
        else if (this.organizationsSelection) this.selectionMode = SelectionMode.Organizations;
        else if (this.devicesSelection) this.selectionMode = SelectionMode.Devices;
        else if (this.scenariosSelection) this.selectionMode = SelectionMode.Scenarios;
    }

    //#region Validation

    isInputInError(formSubmitted: boolean): boolean {
        if (!formSubmitted || !this.validationEnabled) return false; // validation active only after form is being submitted

        if (
            !this.groupsComponent ||
            !this.measuringPointsComponent ||
            !this.projectsComponent ||
            !this.devicesComponent ||
            !this.organizationsComponent ||
            !this.scenariosComponent
        ) {
            return false;
        }

        return (
            (this.requireAtLeastOneResourceSelected &&
                !this.groupsComponent.data.length &&
                !this.measuringPointsComponent.data.length &&
                !this.projectsComponent.data.length &&
                !this.devicesComponent.data.length &&
                !this.organizationsComponent.data.length &&
                !this.scenariosComponent.data.length) ||
            this.isMeasuringPointsInError(formSubmitted) ||
            this.isGroupsInError(formSubmitted) ||
            this.isDevicesInError(formSubmitted) ||
            this.isOrganizationsInError(formSubmitted) ||
            this.isProjectsInError(formSubmitted) ||
            this.isScenariosInError(formSubmitted)
        );
    }

    //#region Reporting validation

    isScenariosInError(formSubmitted: boolean): boolean {
        if (!this.validationEnabled || !this.requiredSelectedScenario || !formSubmitted) return false;

        return !this.scenariosComponent.data.length;
    }

    isMeasuringPointsInError(formSubmitted: boolean): boolean {
        if (!this.validationEnabled || !this.requiredSelectedMeasuringPoint || !formSubmitted) return false;

        const selectedMeasuringPoints = this.measuringPointsComponent.data;

        return (
            !selectedMeasuringPoints.length &&
            (!this.groupsSelection || !this.groupsComponent.data.length) &&
            (!this.projectSelection || !this.projectsComponent.data.length)
        );
    }

    isGroupsInError(formSubmitted: boolean): boolean {
        if (
            !this.validationEnabled ||
            (!this.requiredSelectedGroup && !this.requiredSelectedMeasuringPoint) ||
            !formSubmitted
        )
            return false;

        return (
            !this.groupsComponent.data.length &&
            (!this.measuringPointsSelection || !this.measuringPointsComponent.data.length) &&
            (!this.projectSelection || !this.projectsComponent.data.length)
        );
    }

    isDevicesInError(formSubmitted: boolean): boolean {
        if (!this.validationEnabled || !this.requiredSelectedDevice || !formSubmitted) return false;

        return !this.devicesComponent.data.length;
    }

    isOrganizationsInError(formSubmitted: boolean): boolean {
        if (!this.validationEnabled || !this.requiredSelectedOrganization || !formSubmitted) return false;

        return !this.organizationsComponent.data.length;
    }

    isProjectsInError(formSubmitted: boolean): boolean {
        if (!this.validationEnabled || !this.requiredSelectedProject || !formSubmitted) return false;

        return (
            !this.projectsComponent.data.length &&
            (!this.measuringPointsSelection || !this.measuringPointsComponent.data.length) &&
            (!this.groupsSelection || !this.groupsComponent.data.length)
        );
    }

    //#endregion Reporting validation

    //#endregion Validation
}
