import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DeviceUtils } from "@ramudden/core/utils";
import { BackendRights } from "@ramudden/data-access/models/backend-rights";
import { IDevice, IDeviceSummary } from "@ramudden/data-access/models/device";
import { IMeasuringPoint } from "@ramudden/data-access/models/measuring-point";
import { Rights } from "@ramudden/data-access/models/rights";
import { IUploadDailyMetric } from "@ramudden/data-access/models/upload";
import { IMeasuringPointSummary } from "@ramudden/data-access/models/web";
import { CacheOptions } from "@ramudden/data-access/resource/api";
import { DeviceApi } from "@ramudden/data-access/resource/device.api";
import { MeasuringPointApi } from "@ramudden/data-access/resource/measuring-point.api";
import { DeviceWebApi, MeasuringPointWebApi } from "@ramudden/data-access/resource/web";
import { GlobalEventsService } from "../../../services/global-events.service";
import { CustomTableComponent, FilterType, TableColumn, TableService } from "../table/table.component";

export class UploadDailyMetricRow {
    fields: { [key: string]: number | string } = {};
    downloading = false;
    deviceId: number; // used for creating archive

    constructor(readonly date: Date) {}

    addDeviceOrMeasuringPointData(uploadDailyMetric: IUploadDailyMetric, contextId: number) {
        // Because of the way our columns are handled, data needs to be inputted formatted
        this.fields[`${contextId}-pulseCount`] = uploadDailyMetric.pulseCount;
        if (uploadDailyMetric.pulseCount > 0) {
            this.fields[`${contextId}-pulseConversionRate`] =
                `${(uploadDailyMetric.pulseConversionRate * 100).toFixed(0)}%`;
            this.fields[`${contextId}-skippedPulseRatio`] =
                `${(uploadDailyMetric.skippedPulseRatio * 100).toFixed(0)}%`;

            const tube2Ratio = 1 - uploadDailyMetric.tube1Ratio;
            this.fields[`${contextId}-tube1Ratio`] =
                `${(uploadDailyMetric.tube1Ratio * 100).toFixed(0)}% / ${(tube2Ratio * 100).toFixed(0)}%`;

            this.fields[`${contextId}-tube1RatioCellClass`] = DeviceUtils.getTubeRatioCellClass(
                uploadDailyMetric.tube1Ratio,
            );
            this.fields[`${contextId}-skippedPulseRatioCellClass`] = DeviceUtils.getSkippedRatioCellClass(
                uploadDailyMetric.skippedPulseRatio,
            );
            this.fields[`${contextId}-pulseConversionRateCellClass`] = DeviceUtils.getPulseConversionRateCellClass(
                uploadDailyMetric.pulseConversionRate,
            );
        }
    }
}

@Component({
    selector: "app-upload-metric",
    templateUrl: "./upload-metric.component.html",
})
export class UploadMetricComponent extends CustomTableComponent<UploadDailyMetricRow> implements OnChanges, OnDestroy {
    @Input() device?: IDevice;
    @Input() measuringPoint?: IMeasuringPoint;
    @Input() deleteCommand = true;

    measuringPoints: IMeasuringPointSummary[];
    devices: IDeviceSummary[];
    groupHeaderColumns = new Array<TableColumn>();
    totalRecords: number;
    frozenColumns: TableColumn[];
    hasUploadPulsesInMeasuringPointRight = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly globalEventsService: GlobalEventsService,
        readonly translateService: TranslateService,
        private readonly deviceApi: DeviceApi,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly measuringPointWebApi: MeasuringPointWebApi,
        private readonly deviceWebApi: DeviceWebApi,
    ) {
        super("uploads.component", elementRef, tableService);

        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.hasUploadPulsesInMeasuringPointRight = rights?.hasBackendRight(
                BackendRights.UploadPulsesInMeasuringPoint,
            );
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

        const dateColumn = new TableColumn("date", "general.date", {
            filterType: FilterType.None,
            width: 100,
            sortable: false,
        });
        this.addColumn(dateColumn);
        this.frozenColumns = [dateColumn];
        this.stretchHeight = true;
    }

    override ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    override ngOnChanges(changes: SimpleChanges): void {
        const deviceChange = changes["device"];
        if (deviceChange) {
            this.setDevice(this.device);
        }

        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.setMeasuringPoint(this.measuringPoint);
        }

        super.ngOnChanges(changes);
    }

    setDevice(device: IDevice) {
        this.measuringPoint = null;
        this.device = device;
        this.loading = false;

        this.updateRelevantColumns();
        this.loadTableRows();
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.device = null;
        this.measuringPoint = measuringPoint;
        this.loading = false;

        this.updateRelevantColumns();
        this.loadTableRows();
    }

    override canLoad(): boolean {
        return (!!this.device || !!this.measuringPoint) && !this.loading;
    }

    override loadTableRows() {
        if (!this.canLoad()) return;

        this.setLoading(true);

        // If we have a device, we'll get it, otherwise we query our measuringPoint
        const api = this.device ? this.deviceApi : this.measuringPointApi;

        // If we have a device, we will query its linked measuringPoints. If we have a measuringPoint, we will query its linked devices.
        const webApi = this.device ? this.measuringPointWebApi : this.deviceWebApi;

        const contextId = this.device ? this.device.id : this.measuringPoint.id;
        api.getDailyUploadMetrics$(contextId, this.table.first, this.rowCount).subscribe(
            async (uploadDailyMetricsSearchResult) => {
                const linkedContexts = new Array<IMeasuringPointSummary | IDeviceSummary>();
                const contextColumns = new Array<TableColumn>();

                this.removeColumns((x) => !this.frozenColumns.contains(x));

                let linkedContextIds = uploadDailyMetricsSearchResult.data
                    .map((x) => (this.device ? x.measuringPointId : x.deviceId))
                    .distinct();

                const cacheOptions = new CacheOptions();
                cacheOptions.stopOnCacheFound = true;

                for (const linkedContextId of linkedContextIds) {
                    const context = await webApi.get(linkedContextId);
                    if (!context) {
                        linkedContextIds = linkedContextIds.remove(linkedContextId);
                        continue;
                    }

                    linkedContexts.push(context);

                    this.createColumnsForContext(context);

                    const groupHeaderColumn = new TableColumn(linkedContextId.toString(), "", { resizable: true });
                    groupHeaderColumn.ngStyle["text-align"] = "center";
                    groupHeaderColumn.ngStyle["max-width"] = "none";
                    contextColumns.push(groupHeaderColumn);
                }

                if (this.device) {
                    this.devices = null;
                    this.measuringPoints = linkedContexts as IMeasuringPointSummary[];
                }

                if (this.measuringPoint) {
                    this.measuringPoints = null;
                    this.devices = linkedContexts as IDeviceSummary[];
                }

                this.groupHeaderColumns = contextColumns;

                const uploadDailyMetricRows = new Array<UploadDailyMetricRow>();
                this.totalRecords = uploadDailyMetricsSearchResult.total;

                for (const uploadDailyMetric of uploadDailyMetricsSearchResult.data) {
                    const uploadMetricContextId = this.device
                        ? uploadDailyMetric.measuringPointId
                        : uploadDailyMetric.deviceId;
                    if (!linkedContextIds.contains(uploadMetricContextId)) continue;

                    let row = uploadDailyMetricRows.find((x) => x.date.getTime() === uploadDailyMetric.date.getTime());

                    if (!row) {
                        row = new UploadDailyMetricRow(uploadDailyMetric.date);
                        row.deviceId = uploadDailyMetric.deviceId;

                        uploadDailyMetricRows.push(row);
                    }

                    row.addDeviceOrMeasuringPointData(uploadDailyMetric, uploadMetricContextId);
                }

                this.setData(uploadDailyMetricRows);
                this.setLoading(false);
            },
        );
    }

    get headerColspan(): number {
        return this.hasUploadPulsesInMeasuringPointRight ? 4 : 1;
    }

    private createColumnsForContext(context: IMeasuringPointSummary | IDeviceSummary) {
        this.addColumn(
            new TableColumn(`${context.id}-pulseCount`, "uploads.pulseCount", { width: null, resizable: true }),
        );
        if (this.hasUploadPulsesInMeasuringPointRight) {
            this.addColumn(
                new TableColumn(`${context.id}-pulseConversionRate`, "uploads.pulseConversionRate", {
                    width: 120,
                    resizable: false,
                }),
            );
            this.addColumn(
                new TableColumn(`${context.id}-tube1Ratio`, "uploads.pulseRatio", { width: 127, resizable: false }),
            );
            this.addColumn(
                new TableColumn(`${context.id}-skippedPulseRatio`, "uploads.skippedPulses", { resizable: true }),
            );
        }
    }

    getCellClass(rowData: UploadDailyMetricRow, column: TableColumn): string {
        const split = column.field.split("-");

        if (split.length > 1) {
            const field = split[1];

            if (field === "tube1Ratio" || field === "skippedPulseRatio" || field === "pulseConversionRate") {
                const contextId = split[0];
                return rowData.fields[`${contextId}-${field}CellClass`] as string;
            }
        }

        return "";
    }
}
