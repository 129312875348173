import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { OrganizationUtils } from "@ramudden/core/utils";
import { ExistsValidator, NoWhiteSpaceValidator, PhoneNumberValidator } from "@ramudden/core/validators";
import { SigncoFormArray, SigncoFormGroup } from "@ramudden/data-access/models/form";
import { ExistsValue } from "@ramudden/data-access/models/search";
import { IRoleWithCategory, IUser, Roles, UserCreator, UserUpdater } from "@ramudden/data-access/models/user";
import { RolesApi } from "@ramudden/data-access/resource/roles.api";
import { UserApi } from "@ramudden/data-access/resource/user.api";
import { FormValidationService, MapDataService } from "@ramudden/services";
import { SelectItem } from "primeng/api";
import { DomainData, DomainDataService } from "../../../services/domain-data.service";
import { DomainModelFilterService } from "../../../services/domain-model-filter.service";
import { GlobalEventsService } from "../../../services/global-events.service";
import { PrimeComponentService } from "../../../services/prime-component.service";
import { DialogComponentBase } from "../dialog/dialog.component";

@Component({
    selector: "app-manage-user-dialog",
    templateUrl: "./manage-user.dialog.html",
})
export class ManageUserDialogComponent extends DialogComponentBase implements OnDestroy {
    submitting: boolean;
    callback: (res: IUser) => void;
    organizationFormArray: SigncoFormArray;
    manageUserForm: SigncoFormGroup;
    rolesForm: SigncoFormGroup;
    existingUser: IUser;
    private defaultOrganizationId: number;
    private allRoles: IRoleWithCategory[];

    rolesByCategory: { [key: string]: IRoleWithCategory[] };
    roles: IRoleWithCategory[];

    organizations: SelectItem[];
    languages: SelectItem[];

    private readonly mapDataKey: string;

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly cd: ChangeDetectorRef,
        private readonly globalEventsService: GlobalEventsService,
        private readonly domainDataService: DomainDataService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly userApi: UserApi,
        private readonly rolesApi: RolesApi,
        private readonly primeComponentService: PrimeComponentService,
        private readonly mapDataService: MapDataService,
        private readonly domainModelFilterService: DomainModelFilterService,
    ) {
        super();

        this.mapDataKey = this.mapDataService.createKey();

        this.mapDataService.subscribeToOrganizations(
            this.mapDataKey,
            (organizations) => {
                this.organizations = this.primeComponentService.createDropdownList(
                    OrganizationUtils.addLevel(organizations),
                    (x) => x.id,
                    (x) => x.name,
                    false,
                    "",
                    OrganizationUtils.getStyleClass,
                );
            },
            null,
            false,
        );

        this.rolesApi.get().subscribe((roles) => {
            this.allRoles = roles;
        });

        this.domainDataService.get(DomainData.Language).then((languages) => {
            this.languages = languages;
        });
    }

    ngOnDestroy() {
        this.mapDataService.unsubscribe(this.mapDataKey);
    }

    create(callback?: (res: IUser) => void, defaultOrganizationId: number = null) {
        this.callback = callback;
        this.defaultOrganizationId = defaultOrganizationId;
        this.existingUser = null;

        this.openDialog();
    }

    edit(existingUser: IUser, callback?: (res: IUser) => void) {
        this.callback = callback;
        this.existingUser = existingUser;

        this.openDialog();
    }

    // disable default sorting on keyvalue
    returnZero() {
        return 0;
    }

    protected onOpen() {
        const authorizationInfo = this.globalEventsService.getAuthorizationInfo();
        // Filter out DomainAdministrator role if user is not a domain administrator
        this.roles = authorizationInfo.isDomainAdministrator
            ? this.allRoles
            : this.allRoles.filter((x) => x.roleId !== Roles.DomainAdministrator);
        this.rolesForm = this.formBuilder.group({}) as SigncoFormGroup;
        this.rolesByCategory = {};
        // Map roles by category
        // And add roles to form;
        for (const role of this.roles) {
            this.rolesForm.addControl(role.roleId, new UntypedFormControl(false));
            if (!this.rolesByCategory[role.categoryId]) {
                this.rolesByCategory[role.categoryId] = [];
            }
            this.rolesByCategory[role.categoryId].push(role);
        }

        const sendDefaultEmailControl = this.formBuilder.control(true);
        const emailControl = this.formBuilder.control(
            "",
            [Validators.email, Validators.required],
            ExistsValidator.create((value: ExistsValue) => this.userApi.exists$(value)),
        );
        const organizationsControl = this.formBuilder.control(
            this.defaultOrganizationId != null ? [this.defaultOrganizationId] : null,
            Validators.required,
        );

        this.manageUserForm = this.formBuilder.group({
            firstName: ["", Validators.required, NoWhiteSpaceValidator.validate],
            lastName: ["", Validators.required, NoWhiteSpaceValidator.validate],
            email: emailControl,
            phoneNumber: ["", PhoneNumberValidator.validate],
            gsm: ["", PhoneNumberValidator.validate],
            languageId: null,
            organizations: organizationsControl,
            roles: this.rolesForm,
            sendDefaultEmail: sendDefaultEmailControl,
        }) as SigncoFormGroup;

        if (!this.globalEventsService.hasMultipleOrganizations()) {
            organizationsControl.disable();
        }
        if (this.existingUser) {
            sendDefaultEmailControl.disable();
            emailControl.clearAsyncValidators();

            this.manageUserForm.patchValue({
                firstName: this.existingUser.firstName,
                lastName: this.existingUser.lastName,
                email: this.existingUser.email,
                phoneNumber: this.existingUser.phoneNumber,
                gsm: this.existingUser.gsm,
                languageId: this.existingUser.languageId,
                organizations: this.existingUser.userOrganizations.map((x) => x.organization.id),
            });
            for (const role of this.existingUser.userRoles) {
                const control = this.rolesForm.get(role);
                if (control) {
                    control.setValue(true);
                }
            }
        }

        this.submitting = false;
    }

    protected onClose() {
        this.manageUserForm = null;
    }

    getTranslatableRoleName(roleId: string) {
        return "manageUser." + roleId;
    }
    getDescriptionText(roleId: string) {
        return "manageUser." + roleId + "Description";
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.manageUserForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = (savedUser: IUser) => {
            if (this.existingUser) {
                Object.assign(this.existingUser, savedUser);
            }

            if (this.callback) {
                this.callback(savedUser);
            }

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        if (!this.existingUser) {
            const userCreator = Object.assign(new UserCreator(), this.manageUserForm.value) as UserCreator;
            userCreator.roles = [];
            for (const role of this.roles) {
                if (this.rolesForm.get(role.roleId).value) {
                    userCreator.roles.push(role.roleId);
                }
            }
            this.userApi.create$(userCreator).subscribe(onSuccess, onError);
        } else {
            const userUpdater = new UserUpdater(this.existingUser);

            Object.assign(userUpdater, this.manageUserForm.value);
            userUpdater.roles = [];
            for (const role of this.roles) {
                if (this.rolesForm.get(role.roleId).value) {
                    userUpdater.roles.push(role.roleId);
                }
            }

            this.userApi.update$(userUpdater).subscribe(onSuccess, onError);
        }
    }
}
