import { Injectable } from "@angular/core";
import { ICctvImage } from "@ramudden/data-access/models/cctv-image";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class CctvImageApi extends ApiService<ICctvImage, ICctvImage, ICctvImage> {
    override getRoute(): string {
        return "CctvImages";
    }
}
