import { HttpBackend, HttpClient } from "@angular/common/http";
import { EventEmitter, inject, Injectable } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
    IDomainData,
    ILocalizedEnum,
    ILocalizedStrings,
    ViewModelEnum,
} from "@ramudden/data-access/models/domain-data";
import { SigncoFormGroup } from "@ramudden/data-access/models/form";
import { ConfigurationService, ErrorService } from "@ramudden/services";
import { PrimeComponentService } from "./prime-component.service";

class DomainDataViewModelStorage {
    [key: string]: ViewModelEnum[];
}

// tslint:disable: whitespace
export class ViewModelEnumOptions {
    includeEmpty? = false;
    addEmptyOptionAtEnd? = false;
    emptyLabel? = "form.empty";
    emptyOptionValue? = "";
    orderBy? = (x: ViewModelEnum) => x.label;
}
// tslint:enable: whitespace

export enum DomainData {
    AlarmSeverity,
    AlertLevel,
    AlertType,
    AnalysisType,
    AuditActions,
    AuditTables,
    AuditTrailEventType,
    AuditTrailRelatedObjectType,
    CalculatedTimeRange,
    ContextType,
    DatePeriodType,
    DayOfMonth,
    DayOfWeek,
    DeviceCommandType,
    DeviceType,
    Direction,
    EmailType,
    EmailStatus,
    ExpenseStatus,
    Language,
    MeteoFactorCalculationType,
    Package,
    ProcessStatus,
    PlanningAction,
    RelayType,
    ReleaseChannel,
    ReportStatus,
    ReportTypes,
    Rights,
    Roles,
    TimeAggregationType,
    UploadType,
    VehicleType,
    VehicleSubType,
    ZigbeeRole,
    VehicleCategory,
    AssignmentPriorityValue,
    VmsTypeValue,
    AssignmentStatus,
    ProjectStatus,
    TaskType,
    TaskStatusType,
    TaskStatus,
    MarkingPropertyType,
    SignMaterial,
    SignCategoryValue,
    WorkerContractType,
    ProjectContractType,
    ProjectRoles,
    SubstrateType,
    SignColorType,
    Unit,
    UserError,
    DailyReportsStatus,
    ExternalInputType,
    LiveDataRangeOption,
    DevicePosition,
    OriginDestinationMatchMode,
    OriginDestinationDirection,
    CountingZoneAccessStatus,
    PinnedDataOption,
    DisplaySide,
    NotificationLevel,
    MessageTemplate,
    NotificationType,
    WeatherConditionType,
    AnalysisVehicleType,
    VehicleColor,
    AvsTrafficLightStatus,
    MeasuringPointLogLevel,
}

@Injectable({
    providedIn: "root",
})
export class DomainDataService {
    private errorService = inject(ErrorService);
    private cachedViewModels = new DomainDataViewModelStorage();
    private domainDataLoadPromise: Promise<IDomainData>;

    domainData: IDomainData;
    uponReload = new EventEmitter<void>();

    constructor(
        private readonly httpBackend: HttpBackend,
        private readonly configurationService: ConfigurationService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly translateService: TranslateService,
        private readonly formBuilder: UntypedFormBuilder,
    ) {
        translateService.onLangChange.subscribe(() => {
            this.updateTranslations();
        });
    }

    async get(domainData: DomainData, options?: ViewModelEnumOptions): Promise<ViewModelEnum[]> {
        return await this.createViewModelsOf(DomainData[domainData], options);
    }

    loadLanguage(language: string) {
        const httpClient = new HttpClient(this.httpBackend);
        const url = `${this.configurationService.configuration.url}/v1/domaindata?languages=${language}`;
        return httpClient.get<IDomainData>(url);
    }

    reload(loadAllLanguages = false) {
        const httpClient = new HttpClient(this.httpBackend);
        let url = this.configurationService.configuration.url + "/v1/domaindata";
        if (!loadAllLanguages) {
            const language =
                (localStorage.getItem("language") ||
                    this.getLanguage() ||
                    this.translateService.getBrowserLang()?.split("-")[0]) ??
                "en";
            url += language !== "en" ? `?languages=${language}&languages=en` : `?languages=${language}`;
        }

        this.domainDataLoadPromise = new Promise<IDomainData>((resolve, reject) => {
            httpClient.get<IDomainData>(url).subscribe({
                next: (domainData) => {
                    this.domainData = domainData;
                    this.uponReload.next();
                    resolve(domainData);
                },
                error: (error) => {
                    this.errorService.handleError(error);
                    reject(error);
                },
            });
        });
    }

    public async load(): Promise<IDomainData> {
        if (!this.domainDataLoadPromise && !this.domainData) {
            await this.reload();
        }

        return this.domainDataLoadPromise;
    }

    /// Leave language empty for current language
    translate(stringResourceId: string, language: string = null): string {
        if (!this.domainData.modelTranslations[language || this.getLanguage()]) {
            console.warn("Cannot translate because domain data is not loaded yet");
            return null;
        }

        const translations = this.domainData.modelTranslations[language || this.getLanguage()];
        const translation = translations[stringResourceId.toCamelCase()];

        const toHtml = () => {
            return translation.replace("\n", "<br>");
        };

        return translation ? toHtml() : "";
    }

    translateEnum(domainEnum: string, enumValue: string, formatParameters?: string[]): string {
        if (!enumValue) return null;

        if (!this.domainData) {
            console.warn("Cannot translate because domain data is not loaded yet");
            return null;
        }

        const value = this.domainData.enums[domainEnum]?.values[enumValue] ?? null;
        if (!value) {
            console.warn("Could not find translation for enum value", domainEnum, enumValue);
            return null;
        }
        const translation = value.translations[this.getLanguage()];
        const formatted = this.insertFormatParameters(translation, formatParameters);
        return formatted;
    }

    private insertFormatParameters(input: string, formatParameters?: string[]): string {
        if (!formatParameters) return input;
        let output = input;
        for (let i = 0; i < formatParameters.length; i++) {
            output = output.replace(`{${i}}`, formatParameters[i]);
        }
        return output;
    }

    async getViewModelEnum(domainDataType: string, enumKey: string): Promise<ViewModelEnum> {
        if (!this.domainData) {
            console.warn("Cannot translate because domain data is not loaded yet");
            return null;
        }

        if (!this.domainData.enums.hasOwnProperty(domainDataType)) return null;
        if (!this.domainData.enums[domainDataType].values.hasOwnProperty(enumKey)) return null;

        const language = this.getLanguage();
        const localizedEnum = this.domainData.enums[domainDataType];
        return this.createViewModelEnum(domainDataType, localizedEnum, enumKey, language);
    }

    getLanguages(): Array<string> {
        if (!this.domainData) {
            console.warn("Cannot translate because domain data is not loaded yet");
            return null;
        }

        const languages = new Array<string>();
        const languageValues = this.getEnum(DomainData.Language).values;

        for (const languageEnum in languageValues) {
            if (!languageValues.hasOwnProperty(languageEnum)) continue;
            languages.push(languageEnum);
        }

        return languages;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async createTranslationFormGroup<T>(
        stringResource: string,
        onlyDefaultLanguageRequired = false,
    ): Promise<SigncoFormGroup> {
        const form = this.formBuilder.group({}) as SigncoFormGroup;

        for (const language of this.getLanguages()) {
            const value = stringResource ? this.translate(stringResource, language) : null;

            const control = this.formBuilder.control(value);
            if (!onlyDefaultLanguageRequired || (onlyDefaultLanguageRequired && language === "en"))
                control.setValidators(Validators.required);
            form.addControl(language, control);
        }

        return form;
    }

    updateTranslations() {
        const language = this.getLanguage();
        for (const domainDataType in this.cachedViewModels) {
            if (!this.cachedViewModels.hasOwnProperty(domainDataType)) continue;

            const localizedEnum = this.getEnum(domainDataType);
            const viewModels = this.cachedViewModels[domainDataType];
            for (const viewModel of viewModels) {
                viewModel.label = this.getLabel(localizedEnum, viewModel.value, language);
                viewModel.language = language;
            }
        }
    }

    //#region Private

    private async createViewModelsOf(domainDataType: string, options: ViewModelEnumOptions): Promise<ViewModelEnum[]> {
        if (!options) {
            options = new ViewModelEnumOptions();
        }

        if (!this.domainData) {
            console.warn("Cannot translate because domain data is not loaded yet");
            return null;
        }

        domainDataType = domainDataType.toCamelCase();

        const language = this.getLanguage();
        const localizedEnum = this.getEnum(domainDataType);

        // Retrieve cached
        let viewModelEnums = new Array<ViewModelEnum>();

        for (const enumKey in localizedEnum.values) {
            if (!localizedEnum.values.hasOwnProperty(enumKey)) continue;

            const viewModelEnum = await this.createViewModelEnum(domainDataType, localizedEnum, enumKey, language);
            viewModelEnums.push(viewModelEnum);
        }

        if (options.orderBy) {
            viewModelEnums = viewModelEnums.orderBy(options.orderBy);
        }

        if (options.includeEmpty) {
            const emptyOption = this.primeComponentService.createEmptyOption(
                options.emptyLabel,
                options.emptyOptionValue,
            ) as ViewModelEnum;

            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            options.addEmptyOptionAtEnd ? viewModelEnums.push(emptyOption) : viewModelEnums.unshift(emptyOption); // unshift = add in front
        }

        return viewModelEnums;
    }

    private async createViewModelEnum(
        domainDataType: string,
        localizedEnum: ILocalizedEnum,
        enumKey: string,
        language: string,
    ): Promise<ViewModelEnum> {
        if (!this.cachedViewModels[domainDataType]) {
            this.cachedViewModels[domainDataType] = [];
        } else {
            const cachedViewModelEnum = this.cachedViewModels[domainDataType].find((x) => x.value === enumKey);

            if (cachedViewModelEnum) {
                // SCS-666 - Observed `undefined` label due to suspected timing issues, easiest fix is to invalidate this cache
                if (!cachedViewModelEnum.label) {
                    this.cachedViewModels[domainDataType] =
                        this.cachedViewModels[domainDataType].remove(cachedViewModelEnum);
                } else {
                    return cachedViewModelEnum;
                }
            }
        }

        const label = this.getLabel(localizedEnum, enumKey, language);
        const viewModelEnum = new ViewModelEnum(domainDataType, enumKey, label, language);

        viewModelEnum.relations = await this.getRelations(domainDataType, enumKey);

        this.cachedViewModels[domainDataType].push(viewModelEnum);

        return viewModelEnum;
    }

    private getLabel(localizedEnum: ILocalizedEnum, enumKey: string, language: string): string {
        const localizedString = localizedEnum.values[enumKey];
        return localizedString.translations[language];
    }

    private async getRelations(domainDataType: string, enumKey: string): Promise<ViewModelEnum[]> {
        const relations = new Array<ViewModelEnum>();

        if (!this.domainData.relations.hasOwnProperty(domainDataType)) return relations;

        const domainDataRelations = this.domainData.relations[domainDataType];
        if (!domainDataRelations.hasOwnProperty(enumKey)) return relations;

        const enumRelations = domainDataRelations[enumKey];

        for (const relationDomainDataType in enumRelations) {
            if (enumRelations.hasOwnProperty(relationDomainDataType)) {
                for (const relationEnumKey of enumRelations[relationDomainDataType].map((x) => x.toCamelCase())) {
                    const enumRelationViewModel = await this.getViewModelEnum(relationDomainDataType, relationEnumKey);

                    if (!enumRelationViewModel) {
                        console.error(`${relationDomainDataType}[${relationEnumKey}] was null`);
                        continue;
                    }

                    relations.push(enumRelationViewModel);
                }
            }
        }

        return relations;
    }

    private getEnum(domainDataType: DomainData | string): ILocalizedEnum {
        if (typeof domainDataType === "number") {
            domainDataType = DomainData[domainDataType].toCamelCase();
        }

        if (!this.domainData.enums.hasOwnProperty(domainDataType))
            return { values: {} as ILocalizedStrings } as ILocalizedEnum;

        return this.domainData.enums[domainDataType];
    }

    private getLanguage(): string {
        return this.translateService.currentLang;
    }

    //#endregion Private
}
