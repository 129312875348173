import { inject, Injectable } from "@angular/core";
import { IProgressCreated } from "@ramudden/data-access/models/progress";
import { IReportingParameters } from "@ramudden/data-access/models/report-type";
import { IReportHistory } from "@ramudden/data-access/models/reports";
import { DownloadedFile, DownloadFileService } from "@ramudden/services";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class ReportApi extends ApiService<IReportHistory, void, void> {
    private readonly downloadFileService = inject(DownloadFileService);

    override getRoute(): string {
        return "Reports";
    }

    render$(reportContext: IReportingParameters): Promise<DownloadedFile> {
        const url = `${super.getUrl()}/Render`;

        return this.downloadFileService.downloadBlob(url, reportContext);
    }

    renderWithProgress$(reportContext: IReportingParameters): Observable<IProgressCreated> {
        const url = `${super.getUrl()}/RenderWithProgress`;

        return this.http.post<IProgressCreated>(url, reportContext);
    }

    download$(id: number): Promise<DownloadedFile> {
        const url = `${super.getUrl()}/${id}/Download`;
        return this.downloadFileService.downloadBlob(url);
    }
}
