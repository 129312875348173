import { Injectable } from "@angular/core";
import { INotification } from "@ramudden/data-access/models/notification";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class NotificationApi extends ApiService<INotification, INotification, INotification> {
    override getRoute(): string {
        return "Notifications";
    }

    markAllAsRead$() {
        return this.http.put(`${this.getUrl()}/MarkAllAsRead`, {});
    }
}
